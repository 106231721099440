import {
  CloseOutlined,
  ModeEditOutlineOutlined,
  NavigateBefore,
  NavigateNext,
  ThumbDownAlt,
  ThumbUpAlt,
  VisibilityOutlined,
} from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import { workflow_status } from "AppConstants";
import {
  getFilterActive,
  useCurrentProject,
  useDispatch,
  useSelector,
} from "hooks";
import { useEffect } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import {
  approveSupervisorObjects,
  flagImageForReannotation,
  getSupervisorReviewObjects,
} from "state/actions";
import { useReviewControls } from "../hooks/reviewControls";
import "./style.scss";

export default function SupervisorReview() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const reviewObjects = useSelector((state) => state.objects.supervisorReview);
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const currentImage: number = parseInt(params.image);
  const filterActive = getFilterActive(searchParams);
  const currentProject = useCurrentProject();

  const [goToImageByIndex, currentIndex] = useReviewControls({
    unreviewed_workflow_status: workflow_status.TRUE_POSITIVE,
    tool_path: "supervisor-review",
    reviewObjects: reviewObjects,
  });

  useEffect(() => {
    dispatch(getSupervisorReviewObjects());
  }, [filterActive]);

  if (!reviewObjects.imageIds) {
    return <></>;
  }

  let newUrl = location.pathname;
  const regex = /\/supervisor-review/gi;
  newUrl = newUrl.replaceAll(regex, "");

  const KEY = "bbox";
  const ENABLED_VALUE = "0";

  const toggleBoundingBox = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };

  return (
    <div className="reviewToolWrapper" style={{ marginLeft: "150px" }}>
      <div className="backgroundControll">
        <Tooltip title="Go to previous image" placement="left" arrow>
          <NavigateBefore
            className="navigationArrow"
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={() => goToImageByIndex(currentIndex - 1)}
          />
        </Tooltip>

        <div className="menuWrapper">
          <div className="circleWrapper">
            <div className="circle green">
              <Tooltip title="Approve objects" placement="top" arrow>
                <ThumbUpAlt
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() =>
                    dispatch(
                      approveSupervisorObjects(
                        currentImage,
                        currentProject.id,
                        () => goToImageByIndex(currentIndex + 1)
                      )
                    )
                  }
                />
              </Tooltip>
            </div>
            <div className="circle red">
              <Tooltip
                title="Flag image for reannotation"
                placement="top"
                arrow
              >
                <ThumbDownAlt
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() =>
                    dispatch(
                      flagImageForReannotation(currentImage, true, () =>
                        goToImageByIndex(currentIndex + 1)
                      )
                    )
                  }
                />
              </Tooltip>
            </div>
          </div>
          <div className="subMenuWrapper">
            <Tooltip
              title="Toggle visibility for bounding boxes. Does not change anything on the image"
              placement="bottom"
              arrow
            >
              <VisibilityOutlined
                sx={{ color: "#ffff" }}
                onClick={toggleBoundingBox}
              />
            </Tooltip>
            <Tooltip title="Open image editor" placement="bottom" arrow>
              <Link
                to={`annotate${location.search}`}
                replace
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <ModeEditOutlineOutlined sx={{ color: "#ffff" }} />
              </Link>
            </Tooltip>
            <Tooltip title="Exit review mode" placement="bottom" arrow>
              <CloseOutlined
                sx={{ color: "#ffff" }}
                onClick={() => {
                  navigate(`${newUrl}${location.search}`);
                }}
              />
            </Tooltip>
          </div>
        </div>
        <Tooltip title="Go to next image" placement="right" arrow>
          <NavigateNext
            className="navigationArrow green"
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={() => goToImageByIndex(currentIndex + 1)}
          />
        </Tooltip>
      </div>
      <div className="reviewImagesCount">
        {currentIndex + 1}/{reviewObjects.imageIds.length}
      </div>
    </div>
  );
}
