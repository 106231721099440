import {
  Card,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { getCDNURL } from "authentication/apiDetails";
import type { IImage } from "../types";

interface IImageWithName extends IImage {
  name: string;
}

type Props = {
  image: IImageWithName;
  connected: boolean;
  onConnect: () => void;
  onDisconnect: () => void;
};

export default function Image({
  image,
  connected,
  onConnect,
  onDisconnect,
}: Props) {
  return (
    <Card sx={{ height: "100%" }}>
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="space-between"
        height="100%"
      >
        <img
          src={
            image.src.length > 4
              ? encodeURI(`${getCDNURL()}/image/${image.src}`)
              : "/sample.JPG"
          }
          alt={`image_${image.id}`}
          style={{ width: "100%" }}
        />

        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="space-between"
          px={1}
        >
          <Typography width="100%">{image.name}</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={connected}
                onChange={() => {
                  if (connected) {
                    onDisconnect();
                  } else {
                    onConnect();
                  }
                }}
              />
            }
            label="Connected"
          />
        </Stack>
      </Stack>
    </Card>
  );
}
