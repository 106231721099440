const demoMode = localStorage.getItem("demo_mode")
  ? JSON.parse(localStorage.getItem("demo_mode"))
  : false;

export interface IState {
  adminUIVisible: boolean;
  uploadUIVisible: boolean;
  showFilterWindow: boolean;
  demoMode: boolean;
  sidebarVisible: boolean;
  showExtraInfo: boolean;
  hoverText: string | null;
  clusterVisible: boolean;
  showDatesBeforeProjectName: boolean;
  proximityEnabled: boolean;
  showMarkers: boolean;
  showPowerlines: boolean;
  showZones: boolean;
  showPoles: boolean;
  showUserManager: boolean;
  showMissionManager: boolean;
  showIssueReport: boolean;
  showInventoryReport: boolean;
  showAssetReport: boolean;
  inStreetView: boolean;
  showAssignments: boolean;
  supervisorMode: boolean;
  showClearanceMarkers: boolean;
  showLidarMarkers: boolean;
  zonesLoading: boolean;
  showListView: boolean;
  heatmapEnabled: boolean;
  modalBlocker?: {
    title: string;
    message: string;
  };
}

export const initialState: IState = {
  adminUIVisible: false,
  uploadUIVisible: false,
  showFilterWindow: false,
  demoMode,
  sidebarVisible: false,
  showExtraInfo: false,
  hoverText: null,
  clusterVisible: true,
  showDatesBeforeProjectName: true,
  proximityEnabled: false,
  showMarkers: true,
  showPowerlines: true,
  showZones: true,
  showPoles: true,
  showUserManager: false,
  showMissionManager: false,
  showIssueReport: false,
  showInventoryReport: false,
  showAssetReport: false,
  inStreetView: false,
  showAssignments: true,
  supervisorMode: false,
  showClearanceMarkers: false,
  showLidarMarkers: true,
  zonesLoading: false,
  showListView: false,
  heatmapEnabled: true,
  modalBlocker: null,
};

const uireducer = (
  state: IState = initialState,
  action = { type: null, payload: null }
) => {
  switch (action.type) {
    case "SET_CLEARANCE_VISIBILITY":
      return {
        ...state,
        showClearanceMarkers: action.payload,
      };
    case "SET_BLOCKER_MODAL_MESSAGE":
      return {
        ...state,
        modalBlocker: action.payload,
      };
    case "SET_ZONES_LOADING":
      return {
        ...state,
        zonesLoading: action.payload,
      };
    case "SET_HEATMAP_ENABLED":
      return {
        ...state,
        heatmapEnabled: action.payload,
      };
    case "SET_LIDAR_MARKER_VISIBILITY":
      return {
        ...state,
        showLidarMarkers: action.payload,
      };
    case "SET_SUPERVISOR_MODE": {
      return {
        ...state,
        supervisorMode: action.payload,
      };
    }
    case "SET_ASSIGNMENT_VISIBILITY": {
      return {
        ...state,
        showAssignments: action.payload,
      };
    }
    case "SET_STREETVIEW": {
      return {
        ...state,
        inStreetView: action.payload,
      };
    }
    case "SHOW_ASSET_REPORT": {
      return {
        ...state,
        showAssetReport: action.payload,
      };
    }
    case "SHOW_ISSUE_REPORT": {
      return {
        ...state,
        showIssueReport: action.payload,
      };
    }

    case "SHOW_INVENTORY_REPORT": {
      return {
        ...state,
        showInventoryReport: action.payload,
      };
    }

    case "TOGGLE_ADMIN_UI": {
      return {
        ...state,
        adminUIVisible: !state.adminUIVisible,
      };
    }
    case "TOGGLE_FILTER_WINDOW": {
      return {
        ...state,
        showFilterWindow: !state.showFilterWindow,
      };
    }
    case "TOGGLE_UPLOAD_UI": {
      return {
        ...state,
        uploadUIVisible: !state.uploadUIVisible,
      };
    }
    case "TOGGLE_DEMO_MODE": {
      localStorage.setItem("demo_mode", JSON.stringify(action.payload));
      return {
        ...state,
        demoMode: action.payload,
      };
    }
    case "SHOW_USER_MANAGER": {
      return {
        ...state,
        showUserManager: action.payload,
      };
    }

    case "SET_LIST_VIEW": {
      return {
        ...state,
        showListView: action.payload,
      };
    }

    case "SHOW_MISSION_MANAGER": {
      return {
        ...state,
        showMissionManager: action.payload,
      };
    }

    case "SET_SIDEBAR_VISIBILITY": {
      return {
        ...state,
        sidebarVisible: action.payload,
      };
    }

    case "SET_SHOW_EXTRA_INFO": {
      return {
        ...state,
        showExtraInfo: action.payload,
      };
    }

    case "SET_CLUSTER_VISIBLE": {
      return {
        ...state,
        clusterVisible: action.payload,
      };
    }

    case "SET_SHOW_DATES_BEFORE_PROJECT_NAME": {
      return {
        ...state,
        showDatesBeforeProjectName: action.payload,
      };
    }

    case "SET_PROXIMITY": {
      return {
        ...state,
        proximityEnabled: action.payload,
      };
    }

    case "SET_HOVER_TEXT": {
      return {
        ...state,
        hoverText: action.payload,
      };
    }

    case "SET_MARKERS_VISIBILITY": {
      return {
        ...state,
        showMarkers: action.payload,
      };
    }

    case "SET_POWERLINES_VISIBILITY": {
      return {
        ...state,
        showPowerlines: action.payload,
      };
    }

    case "SET_ZONES_VISIBILITY": {
      return {
        ...state,
        showZones: action.payload,
      };
    }

    case "SET_POLE_VISIBILITY": {
      return {
        ...state,
        showPoles: action.payload,
      };
    }

    default:
      return state;
  }
};

export default uireducer;
