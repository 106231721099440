import { useFlags } from "launchdarkly-react-client-sdk";
import ModalBlocker from "./ModalBlocker";

export default function FlagProtectedModalBlocker() {
  // Render conditionally on feature flags
  const { modalBlocker } = useFlags();

  // https://app.launchdarkly.com/projects/default/flags/modal-blocker
  if (!modalBlocker) {
    return null;
  }

  return <ModalBlocker />;
}
