import { CSSProperties, useContext, useRef, useEffect, useState } from "react";
import styled from "@emotion/styled";
import { getTopLeft } from "../utils";
import { AnnotationContext, CanvasContext } from "../provider";
import { ISpecialAnnotation } from "../provider/AnnotationProvider/context";
import { useBoxListener, useEdgeListener, useRotateListener } from "../hooks";
import { getClientObjectTypeName } from "utils/utils";
import NoneDefectBoxButton from "./NoneDefectBoxButton";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { workflow_status } from "AppConstants";
import { useSelector } from "hooks";

const EdgeDot = styled.div`
  width: 7px;
  height: 7px;
  position: absolute;
  touch-action: none;
`;

const EdgeSquare = styled.div`
  width: 10px;
  height: 10px;
  position: absolute;
  touch-action: none;
`;

const EdgeDotTopLeft = styled(EdgeDot)`
  top: -2px;
  left: -2px;
  border-radius: 50%;
`;

const EdgeDotTopRight = styled(EdgeDot)`
  top: -2px;
  right: -2px;
  border-radius: 50%;
`;

const EdgeDotBottomLeft = styled(EdgeDot)`
  bottom: -2px;
  left: -2px;
  border-radius: 50%;
`;

const EdgeDotBottomRight = styled(EdgeDot)`
  bottom: -2px;
  right: -2px;
  border-radius: 50%;
`;

const EdgeSquareTopLeft = styled(EdgeSquare)`
  top: -5px;
  left: -5px;
  border: 2px solid white;
  cursor: nw-resize;
`;

const EdgeSquareTopRight = styled(EdgeSquare)`
  top: -5px;
  right: -5px;
  border: 2px solid white;
  cursor: ne-resize;
`;

const EdgeSquareBottomLeft = styled(EdgeSquare)`
  bottom: -5px;
  left: -5px;
  border: 2px solid white;
  cursor: sw-resize;
`;

const EdgeSquareBottomRight = styled(EdgeSquare)`
  bottom: -5px;
  right: -5px;
  border: 2px solid white;
  cursor: se-resize;
`;

type Props = {
  annotation: ISpecialAnnotation;
  canvasWidth: number;
  imageDimensions;
  canvasRef;
};

interface IAnnotationItem {
  categories: ISpecialAnnotation["categories"][number];
  client_types: ISpecialAnnotation["client_types"][number];
  confidence: ISpecialAnnotation["confidence"][number];
  creator: ISpecialAnnotation["creator"][number];
  is_defect: ISpecialAnnotation["is_defect"][number];
  processed: ISpecialAnnotation["processed"][number];
  severities: ISpecialAnnotation["severities"][number];
  skyqraft_hidden: ISpecialAnnotation["skyqraft_hidden"][number];
  type_comment: ISpecialAnnotation["type_comment"][number];
  type_id: ISpecialAnnotation["type_id"][number];
  types: ISpecialAnnotation["types"][number];
  updated_by: ISpecialAnnotation["updated_by"][number];
  workflow_status: ISpecialAnnotation["workflow_status"][number];
}

function getAnnotationItems(annotation: ISpecialAnnotation): IAnnotationItem[] {
  return annotation.type_id.map((_, i) => ({
    categories: annotation.categories[i],
    client_types: annotation.client_types[i],
    confidence: annotation.confidence[i],
    creator: annotation.creator[i],
    is_defect: annotation.is_defect[i],
    processed: annotation.processed[i],
    severities: annotation.severities[i],
    skyqraft_hidden: annotation.skyqraft_hidden[i],
    type_comment: annotation.type_comment[i],
    type_id: annotation.type_id[i],
    types: annotation.types[i],
    updated_by: annotation.updated_by[i],
    workflow_status: annotation.workflow_status[i],
  }));
}

function validateAnnotationItem(annotationItem: IAnnotationItem) {
  switch (annotationItem.workflow_status) {
    case workflow_status.FALSE_POSITIVE:
    case workflow_status.SUPERVISOR_FALSE_POSITIVE:
      return false;
    default:
      return true;
  }
}

export default function CanvasBox({
  canvasWidth,
  annotation,
  imageDimensions,
  canvasRef,
}: Props) {
  const user = useSelector((state) => state.user);
  // Compute information to place the box properly
  const boxRef = useRef<HTMLDivElement | null>(null);
  const topLeftRef = useRef<HTMLDivElement | null>(null);
  const topRightRef = useRef<HTMLDivElement | null>(null);
  const bottomLeftRef = useRef<HTMLDivElement | null>(null);
  const bottomRightRef = useRef<HTMLDivElement | null>(null);
  const rotateRef = useRef<HTMLDivElement | null>(null);
  const { matrix } = useContext(CanvasContext);
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const {
    setAnnotations,
    annotations,
    setSelectedAnnotation,
    steelworkObjectTypeId,
  } = useContext(AnnotationContext);
  const topLeft = getTopLeft(matrix);
  const [copiedBox, setCopiedBox] = useState<ISpecialAnnotation | null>(null);

  function getObjectType(
    ot: typeof objectTypes,
    annotationItem: IAnnotationItem
  ) {
    const targetType = ot.find((o) => o.id === annotationItem.types);
    return targetType;
  }

  useEffect(() => {
    // Add event listener for shortcut keys
    if (!annotation.highlighted) return;
    // Listen for delete key
    const handleDelete = (e: KeyboardEvent) => {
      if (!annotation.highlighted) return;
      if (e.key === "Delete") {
        const newAnnotations = annotations.filter(
          (a) => a.id !== annotation.id
        );
        setAnnotations(newAnnotations);
      }
    };
    //add listener for copy paste
    const handleCopyPaste = (e: KeyboardEvent) => {
      if (!annotation.highlighted) return;
      if (e.key === "c" && e.ctrlKey) {
        setCopiedBox(annotation);
      }
      if (e.key === "v" && e.ctrlKey) {
        setSelectedAnnotation(null);
        if (copiedBox) {
          const newAnnotation = {
            ...copiedBox,
            x: copiedBox.x + 0.03,
            y: copiedBox.y + 0.03,
            id: `n${Math.random().toString(36)}`,
          };
          setAnnotations([...annotations, newAnnotation]);
        }
        setCopiedBox(null);
      }
    };
    window.addEventListener("keydown", handleDelete);
    window.addEventListener("keydown", handleCopyPaste);
    return () => {
      window.removeEventListener("keydown", handleDelete);
      window.removeEventListener("keydown", handleCopyPaste);
    };
  }, [
    annotation,
    copiedBox,
    annotation.highlighted,
    annotations,
    setAnnotations,
    setSelectedAnnotation,
  ]);

  useBoxListener({
    boxRef,
    canvasRef,
    currentBox: annotation,
    canvasWidth,
    rotation: annotation.rotation,
  });
  useEdgeListener({
    boxRef: topLeftRef,
    currentBox: annotation,
    canvasWidth,
    edge: "topLeft",
    canvasRef,
  });
  useEdgeListener({
    boxRef: topRightRef,
    currentBox: annotation,
    canvasWidth,
    edge: "topRight",
    canvasRef,
  });
  useEdgeListener({
    boxRef: bottomLeftRef,
    currentBox: annotation,
    canvasWidth,
    edge: "bottomLeft",
    canvasRef,
  });
  useEdgeListener({
    boxRef: bottomRightRef,
    currentBox: annotation,
    canvasWidth,
    edge: "bottomRight",
    canvasRef,
  });
  useRotateListener({
    boxRef: rotateRef,
    currentBox: annotation,
    canvasRef,
  });

  const sharedStyle: CSSProperties = {
    borderWidth: 2,
    position: "absolute",
    touchAction: "none",
    pointerEvents: annotation.highlighted ? "auto" : "none",
  };

  const annotationItems = getAnnotationItems(annotation);
  const validAnnotationItems = annotationItems.filter(validateAnnotationItem);
  const validObjectTypes = validAnnotationItems.map((a) =>
    getObjectType(objectTypes, a)
  );
  const clientObject = annotation.types.map((t) => getClientObjectTypeName(t));

  // Just hide it if the annotation is not visible
  if (annotation.visible === false) {
    return null;
  }

  // Set a default color for the box
  let color = "#000000";

  if (validObjectTypes.length > 0) {
    // Get the color of the annotation
    const objectType = validObjectTypes[0];
    color = objectType?.color ?? "#000000";

    // Override the color with the "class color" if selected by the user
    if (user.annotator_color && objectType?.class_color) {
      color = objectType.class_color;
    }
  }

  //check if annotation.workflow_status only contains 4s and return null in that case to not show the box
  if (annotation.workflow_status.every((v) => v === 4)) {
    return null;
  }

  if (annotation.highlighted) {
    return (
      <div
        style={{
          left:
            topLeft.x +
            (annotation.x * imageDimensions?.naturalWidth) / matrix.a,
          top:
            topLeft.y +
            (annotation.y * imageDimensions.naturalHeight) / matrix.d,
          width: (annotation.w * imageDimensions.naturalWidth) / matrix.a,
          height: (annotation.h * imageDimensions.naturalHeight) / matrix.d,
          rotate: `${annotation.rotation}deg`,
          borderStyle: "dashed",
          borderColor: "white",
          cursor: "move",
          zIndex: 100,
          ...sharedStyle,
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <NoneDefectBoxButton annotation={annotation} />
        <EdgeSquareBottomLeft ref={bottomLeftRef} />
        <EdgeSquareBottomRight ref={bottomRightRef} />
        <EdgeSquareTopLeft ref={topLeftRef} />
        <EdgeSquareTopRight ref={topRightRef} />
        {/* This is for rotating the bb */}
        {annotation.types.includes(steelworkObjectTypeId) && (
          <div
            ref={rotateRef}
            style={{
              top:
                (annotation.h * imageDimensions.naturalHeight) / matrix.d / 2 -
                5,
              //if changing the offset here, change the offset in the rotate function in useRotateListener
              right: -20 / matrix.a,
              width: 10 / matrix.a,
              height: 10 / matrix.d,
              position: "absolute",
              touchAction: "none",
            }}
          >
            <RotateLeftIcon htmlColor="white" />
          </div>
        )}

        <div
          ref={boxRef}
          style={{
            margin: 2,
            borderWidth: 2,
            borderStyle: "solid",
            borderColor: color,
            height: "calc( 100% - 8px )",
            zIndex: 101,
          }}
        />

        <div
          style={{
            marginBottom: 5,
            width: "max-content",
            position: "absolute",
            bottom: "100%",
            left: 0,
            fontSize: 10,
            color: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: 2,
            touchAction: "none",
          }}
        >
          {clientObject.map((object) => {
            return <p style={{ margin: 2, zIndex: 3 }}>{object?.name}</p>;
          })}
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        left:
          topLeft.x + (annotation.x * imageDimensions?.naturalWidth) / matrix.a,
        top:
          topLeft.y + (annotation.y * imageDimensions.naturalHeight) / matrix.d,
        width: (annotation.w * imageDimensions.naturalWidth) / matrix.a,
        height: (annotation.h * imageDimensions.naturalHeight) / matrix.d,
        zIndex: annotation.hover ? 100 : 10,
        rotate: `${annotation.rotation}deg`,
        ...sharedStyle,
      }}
    >
      {annotation.hover && (
        <div
          style={{
            marginBottom: 5,
            width: "max-content",
            position: "absolute",
            bottom: "100%",
            left: 0,
            fontSize: 10,
            color: "white",
            backgroundColor: "rgba(0,0,0,0.5)",
            padding: 2,
            touchAction: "none",
          }}
        >
          {clientObject.map((object) => {
            return (
              <p
                style={{
                  margin: 2,
                }}
              >
                {object?.name}
              </p>
            );
          })}
        </div>
      )}
      <NoneDefectBoxButton annotation={annotation} />
      <EdgeDotBottomLeft
        ref={bottomLeftRef}
        style={{
          backgroundColor: annotation.is_defect.includes(true)
            ? "red"
            : "#00ff44",
        }}
      />
      <EdgeDotBottomRight
        ref={bottomRightRef}
        style={{
          backgroundColor: annotation.is_defect.includes(true)
            ? "red"
            : "#00ff44",
        }}
      />
      <EdgeDotTopLeft
        ref={topLeftRef}
        style={{
          backgroundColor: annotation.is_defect.includes(true)
            ? "red"
            : "#00ff44",
        }}
      />
      <EdgeDotTopRight
        ref={topRightRef}
        style={{
          backgroundColor: annotation.is_defect.includes(true)
            ? "red"
            : "#00ff44",
        }}
      />
      <div
        ref={boxRef}
        style={{
          borderWidth: 2,
          borderStyle: "solid",
          borderColor: color,
          height: "calc( 100% - 4px )",
        }}
      />
    </div>
  );
}
