import React from "react";
import { Grid, Stack } from "@mui/material";
import FilterButtons from "./FilterButtons";

type Props = {
  children: React.ReactNode;
  filterActive: boolean;
  setFilterActive: (value: boolean) => void;
  filterValue: boolean;
  setFilterValue: (value: boolean) => void;
};

export default function ImageContainer({
  children,
  filterActive,
  setFilterActive,
  filterValue,
  setFilterValue,
}: Props) {
  return (
    <Stack sx={{ overflowY: "auto", backgroundColor: "lightgray", p: 1 }}>
      <FilterButtons
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      />
      <Grid
        container
        spacing={1}
        sx={{
          minHeight: 300,
        }}
      >
        {React.Children.map(children, (child) => (
          <Grid item xs={12} sm={6} md={4} lg={3}>
            {child}
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
}
