import { useEffect } from "react";
import { io } from "socket.io-client";
import { useFlags } from "launchdarkly-react-client-sdk";
import { EventHint, captureException } from "@sentry/react";
import { getSocketEndpoint } from "authentication/apiDetails";

export default function SocketIO(props) {
  // Render conditionally on feature flags
  const { webSocket } = useFlags();

  useEffect(() => {
    // https://app.launchdarkly.com/projects/default/flags/web-socket
    if (!webSocket) {
      return;
    }
    const endpoint = getSocketEndpoint();

    if (endpoint) {
      const socket = io(endpoint, {
        withCredentials: true,
        transports: ["websocket"], // Only use websocket transport since longpolling requires sticky sessions
      });

      socket.on("connect_error", (error) => {
        // Capture the exception
        const exceptionHint: EventHint = {
          event_id: "socketIO.connect_error",
          originalException: error,
          data: {
            activeSocket: socket.active,
          },
        };
        captureException(error, exceptionHint);
      });

      return () => {
        socket.disconnect();
      };
    }
  }, [webSocket]);

  return null;
}
