import { Dialog, Divider, Typography } from "@mui/material";
import { useParams, useSearchParams } from "react-router-dom";
import {
  useSearchQueryItem,
  useCurrentAnnotation,
  usePoleImages,
} from "./hooks";
import type { IImage } from "./types";
import TopBar from "./components/TopBar";
import ImageContainer from "./components/ImageContainer";
import Image from "./components/Image";
import { useState } from "react";

function NotFound() {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <Dialog
      open={true}
      sx={{ zIndex: 20000, p: 2 }}
      onClose={() => {
        searchParams.delete("box");
        setSearchParams(searchParams);
      }}
    >
      <Typography>Not found</Typography>
    </Dialog>
  );
}

export default function ImageConnectorDialog() {
  const [filterActive, setFilterActive] = useState(false);
  const [filterValue, setFilterValue] = useState(false);
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const projectID = Number.parseInt(params.mission);
  const { item: poleID } = useSearchQueryItem("pole");
  const { item: boxID } = useSearchQueryItem("box");
  const { images } = usePoleImages(poleID, projectID);

  const { currentAnnotation, addItemToBox, deleteBoxItem } =
    useCurrentAnnotation(boxID);

  if (!currentAnnotation) {
    return <NotFound />;
  }

  function removeConnection(itemID: number) {
    deleteBoxItem(poleID, boxID, itemID);
  }

  function onClose() {
    if (searchParams.has("addPoleItem")) {
      searchParams.delete("addPoleItem");
    }
    if (searchParams.has("box")) {
      searchParams.delete("box");
    }
    setSearchParams(searchParams);
  }

  async function connectImage(image: IImage) {
    await addItemToBox(poleID, boxID, image.id);
  }

  const annotationImageIDs = currentAnnotation.items.map(
    (item) => item.image.id
  );

  const filteredImages = !filterActive
    ? images
    : images.filter((image) => {
        if (filterValue) {
          return annotationImageIDs.includes(image.id);
        }
        return !annotationImageIDs.includes(image.id);
      });

  return (
    <Dialog open={true} onClose={onClose} maxWidth="xl">
      <TopBar
        title="Connect images to this segment of the pole"
        onClose={onClose}
      />
      <Divider />
      <ImageContainer
        filterActive={filterActive}
        setFilterActive={setFilterActive}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
      >
        {filteredImages.map((image) => (
          <Image
            image={image}
            connected={annotationImageIDs.includes(image.id)}
            onDisconnect={() => {
              const annotationItem = currentAnnotation.items.find(
                (item) => item.image.id === image.id
              );
              removeConnection(annotationItem.id);
            }}
            onConnect={() => {
              connectImage(image);
            }}
          />
        ))}
      </ImageContainer>
    </Dialog>
  );
}
