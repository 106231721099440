import { Image, ThreeDRotation, Whatshot } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { getLidarURL } from "authentication/apiDetails";
import {
  useDispatch,
  useObjectTypeFilter,
  useSelector,
  useSeverityFilter,
  useTranslation,
} from "hooks";
import isEqual from "lodash/isEqual";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import { useRafState } from "react-use";
import {
  resetThermalData,
  setFilteredAnnotations,
  setThermalData,
} from "state/actions";
import { setLidarWindow } from "state/actions";
import store from "state/store";
import { compose, scale, translate } from "transformation-matrix";
import { Matrix } from "transformation-matrix-js";
import { useEventCallback } from "utils";
import { authorizedGet } from "utils/request";
import { CanvasContext } from "views/AnnotationTool/provider";
import { getTopLeft } from "views/AnnotationTool/utils";
import ThermalViewer from "views/ThermalViewer";
import { ReviewMode } from "..";
import { useInputListener } from "../hooks/inputListener";
import AnnotationsList from "./AnnotationsList";
import CanvasContainer from "./CanvasContainer";
import RegionShapes from "./RegionShapes/RegionShapes";
import SpeedImage from "./SpeedImage";

import { IAnnotation } from "views/AnnotationTool/api";
import "./style.scss";

interface IProps {
  onLoad: () => void;
  reviewMode: ReviewMode;
  widthScale: number;
}

export const ImageCanvas = function ImageCanvas({
  onLoad,
  reviewMode,
  widthScale,
}: IProps) {
  const dispatch = useDispatch();
  const RGBImageTranslation = useTranslation("RGBImage");
  const thermalImageTranslation = useTranslation("ThermalImage");
  const lidarImageTranslation = useTranslation("LidarImage");
  const setDateTranslation = useTranslation("SetDate");
  const ClearTranslation = useTranslation("Clear");
  const CloseTranslation = useTranslation("Close");
  const getDataFailedTranslation = useTranslation("GetDataFailed");
  const SaveTranslation = useTranslation("Save");

  const customerId = useSelector((state) => state.mission?.id);
  const poleID = useSelector((state) => state.image?.current?.pole_id);
  const thermalData = useSelector((state) => state.imageViewer.thermalData);
  const zoomSpeed = useSelector((state) => state.imageViewer.zoomSpeed);
  const fixableDefects = useSelector(
    (state) =>
      state.user.missions.find((m) => m.id === state.mission?.id)
        ?.fixable_defects
  );
  const connectedImages = useSelector(
    (state) => state.image?.current?.connected
  );
  const imageLidar = useSelector((state) => state.image?.current?.lidar || "");
  const imageLidarProjectId = useSelector(
    (state) => state.image?.current?.lidar_project_id
  );
  const lidarWindow = useSelector((state) => state.imageViewer.lidarWindow);
  const demoMode = useSelector((state) => state.ui.demoMode);
  const cdn = useSelector((state) => state.image?.current?.cdn);
  const imageId = useSelector((state) => state.image?.current?.id);
  const annotations = useSelector((state) => state.image.annotations);
  const filteredAnnotations = useSelector(
    (state) => state.image.filteredAnnotations
  );
  const reviewLoading = useSelector((state) => state.objects.reviewLoading);

  const selectedPreviousDefectImage = useSelector(
    (state) => state.imageViewer.selectedPreviousDefectImage
  );

  const autoSpeedZoomInReviewModes = useSelector(
    (state) => state.user.auto_speed_zoom_in_review_modes
  );

  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const isThermal = location.pathname.includes("/thermal");
  const image = params.image;

  const [pipes, setPipes] = useState([]);
  const [annotationListScroll, setAnnotationListScroll] = useState(false);

  const canvasEl = useRef(null);
  const [loading, setLoading] = useRafState(true);
  const [lockedRegion, setLockedRegion] = useRafState(null);
  const [hoveredRegion, setHoveredRegion] = useRafState(null);
  const [selectedRegions, setSelectedRegions] = useRafState([]);
  const [getDate, setGetDate] = useRafState({
    visible: false,
    callback: (e) => {},
    comment: "",
    isProcessDefect: false,
  });
  // Annotations that we do not filter out despite them not fitting our current filter.
  // Used for review modes when you change the type of an object so they don't instantly disappear. Reset when switching images.
  const [forceIncludedAnnotations, setForceIncludedAnnotations] = useState<
    number[]
  >([]);

  const {
    matrix,
    imageDimensions,
    changeImageDimensions,
    setMatrix,
    dragged,
    setZoomSpeed,
  } = useContext(CanvasContext);

  const [canvasWidth, setCanvasWidth] = useRafState(900);
  const [canvasHeight, setCanvasHeight] = useRafState(900);
  // Update canvas zoomSpeed.
  useEffect(() => {
    setZoomSpeed(annotationListScroll ? 0 : zoomSpeed);
  }, [zoomSpeed, setZoomSpeed, annotationListScroll]);

  useEffect(() => {
    if (!loading && !!image && !!customerId) {
      authorizedGet<{ pipes }>(`/image/${image}/pipes`)
        .then(({ pipes }) => setPipes(pipes))
        .catch(() => setPipes([]));
      if (isThermal) {
        authorizedGet(`/image/${image}/thermal`)
          .then((response) => {
            //check if the response is a json object, otherwise try to parse it with JSON.parse
            const isJson = typeof response === "object";
            const responseJson = isJson ? response : JSON.parse(response);
            if (responseJson?.temperature?.length > 10) {
              store.dispatch(setThermalData(responseJson));
            }
          })
          .catch(() => resetThermalData());
      } else {
        resetThermalData();
      }
    }
  }, [image, loading, customerId, isThermal]);

  const imageLoaded = Boolean(imageDimensions?.naturalWidth);

  const [speedZoomToggle, setSpeedZoomToggle] = useState(-1);

  const speedZoom = (x, y, w, h, id) => {
    const zoomInScale = w + h > 0.05 ? w + h : 0.05;

    if (speedZoomToggle !== id) {
      if (speedZoomToggle > -1 && id === "image") {
        const newCanvasWidth = canvasEl.current.clientWidth;
        setMatrix(
          calculateScaleMatrix(
            newCanvasWidth,
            canvasEl.current.clientHeight,
            imageDimensions.naturalWidth,
            imageDimensions.naturalHeight
          )
        );
        setSpeedZoomToggle(-1);
      } else {
        const zoomMatrix = Matrix.from(
          zoomInScale,
          0,
          0,
          zoomInScale,
          imageDimensions.naturalWidth * x +
            (imageDimensions.naturalWidth * w) / 2 -
            (clientWidth / 2) * zoomInScale,
          imageDimensions.naturalHeight * y +
            (imageDimensions.naturalHeight * h) / 2 -
            (canvasHeight / 2) * zoomInScale
        );
        setMatrix(zoomMatrix);
        setSpeedZoomToggle(id);
      }
    } else {
      const newCanvasWidth = canvasEl.current.clientWidth;
      setMatrix(
        calculateScaleMatrix(
          newCanvasWidth,
          canvasEl.current.clientHeight,
          imageDimensions.naturalWidth,
          imageDimensions.naturalHeight
        )
      );
      setSpeedZoomToggle(-1);
    }
  };

  //Function to toggle selected boxes boxes in the annotation list and image viewer
  const toggleBox = (id: string[]) => {
    const addIds = [];
    const subIds = [];

    for (const i of id) {
      if (selectedRegions.includes(i)) {
        //check if the annotation has an itemID
        const itemID = annotations.filter((a) => a.id === i)[0]?.item_id;
        if (itemID) {
          const itemIDs = searchParams.get("itemId")?.split(",");
          //if the itemID is in the searchParams then remove it
          if (itemIDs?.includes(itemID.toString())) {
            itemIDs.splice(itemIDs.indexOf(itemID.toString()), 1);
            if (itemIDs.length === 0) {
              searchParams.delete("itemId");
            } else {
              searchParams.set("itemId", itemIDs.join(","));
            }
          }
          setSearchParams(searchParams);
        }
        subIds.push(i);
      } else {
        //check if the annotation has an itemID
        const itemID = annotations.filter((a) => a.id === i)[0]?.item_id;

        if (itemID) {
          const itemIDs = searchParams.get("itemId")?.split(",");
          //if there are no itemIDs in the searchParams then add it
          if (!itemIDs) {
            searchParams.set("itemId", itemID.toString());
          } else {
            if (!itemIDs?.includes(itemID.toString())) {
              itemIDs.push(itemID.toString());
              searchParams.set("itemId", itemIDs.join(","));
            } else {
              searchParams.set("itemId", itemIDs.join(","));
            }
          }
          setSearchParams(searchParams);
        }
        addIds.push(i);
      }
    }

    addIds.length > 0 &&
      setSelectedRegions((current) => {
        return [...current, ...addIds];
      });
    subIds.length > 0 &&
      setSelectedRegions((current) => {
        return current.filter((e) => !subIds.includes(e));
      });
  };

  const { objectTypeFilter } = useObjectTypeFilter();
  const { severityFilter } = useSeverityFilter();

  /**
   * The top left corner of the image relative to the canvas.
   */
  const imageTopLeft = getTopLeft(matrix);
  const imageBottomRight = {
    x: imageTopLeft.x + imageDimensions.naturalWidth / matrix.a,
    y: imageTopLeft.y + imageDimensions.naturalHeight / matrix.d,
  };

  useInputListener({
    canvasRef: canvasEl,
    annotations: filteredAnnotations,
    setHoveredRegion: setHoveredRegion,
    setLockedRegion: setLockedRegion,
    reviewMode: reviewMode,
    speedZoom: speedZoom,
    toggleBox: toggleBox,
    setSelectedRegions: setSelectedRegions,
  });

  // All filtering for annotations in the image canvas is handled here.
  // This is then delivered to RegionShapes & AnnotationList who simply display it.
  useEffect(() => {
    let tempAnnotations = annotations || [];

    if (reviewMode !== ReviewMode.None) {
      // Filter for objects that the filter selects.
      tempAnnotations = tempAnnotations.map((annotation) => {
        const filteredConfidence = [];
        const filteredCreator = [];
        const filteredTypes = [];
        const filteredProcessed = [];
        const filteredWorkflowStatus = [];
        const filteredSeverities = [];
        const filteredSkyqraftHidden = [];
        const filteredTypeId = [];
        const filteredTypeComment = [];
        const filteredUpdatedBy = [];

        for (let i = 0; i < annotation.types.length; i++) {
          const annotationType = annotation.types[i];

          let included =
            objectTypeFilter.includes(annotationType) ||
            objectTypeFilter.length === 0;

          if (
            reviewMode === ReviewMode.FalsePositiveReview ||
            reviewMode === ReviewMode.SuperFalsePositiveReview
          ) {
            included = included && annotation.workflow_status[i] === 4;
          } else if (reviewMode === ReviewMode.SupervisorReview) {
            included = included && annotation.workflow_status[i] < 4;
          } else {
            included = included && annotation.workflow_status[i] !== 4;
          }

          if (
            severityFilter.length &&
            !severityFilter.includes(annotation.severities[i])
          ) {
            included = false;
          }

          const forceIncluded = forceIncludedAnnotations.includes(
            Number(annotation.type_id[i])
          );

          included = included || forceIncluded;

          if (included) {
            filteredConfidence.push(annotation.confidence[i]);
            filteredCreator.push(annotation.creator[i]);
            filteredTypes.push(annotationType);
            filteredProcessed.push(annotation.processed[i]);
            filteredWorkflowStatus.push(annotation.workflow_status[i]);
            filteredSeverities.push(annotation.severities[i]);
            filteredSkyqraftHidden.push(annotation.skyqraft_hidden[i]);
            filteredTypeId.push(annotation.type_id[i]);
            filteredTypeComment.push(annotation.type_comment[i]);
            filteredUpdatedBy.push(annotation.updated_by[i]);
          }
        }

        return {
          ...annotation,
          confidence: filteredConfidence,
          creator: filteredCreator,
          types: filteredTypes,
          processed: filteredProcessed,
          workflow_status: filteredWorkflowStatus,
          severities: filteredSeverities,
          skyqraft_hidden: filteredSkyqraftHidden,
          type_id: filteredTypeId,
          type_comment: filteredTypeComment,
          updated_by: filteredUpdatedBy,
        } as IAnnotation;
      });

      tempAnnotations = tempAnnotations.filter(
        (annotation) => annotation.types.length > 0
      );
    }

    // Don't trigger re-renders when nothing actually changed.
    if (!isEqual(tempAnnotations, filteredAnnotations)) {
      dispatch(setFilteredAnnotations(tempAnnotations, Number(image), true));
    }
  }, [
    reviewMode,
    annotations,
    objectTypeFilter,
    forceIncludedAnnotations,
    setForceIncludedAnnotations,
  ]);

  // Marker for if speedZoom has been triggered for this image.
  const [speedZoomTriggered, setSpeedZoomTriggered] = useState(false);

  useEffect(() => {
    if (
      !imageLoaded ||
      speedZoomTriggered ||
      filteredAnnotations.length === 0 ||
      reviewMode === ReviewMode.None
    )
      return;

    // Separated set to prevent zooming after entering an image.
    // Might otherwise happen if you turn the setting on after loading.
    setSpeedZoomTriggered(true);

    if (autoSpeedZoomInReviewModes) {
      const annotation = filteredAnnotations[0];

      speedZoom(
        annotation.x,
        annotation.y,
        annotation.w,
        annotation.h,
        annotation.id
      );

      toggleBox([annotation.id]);
    }
  }, [filteredAnnotations, imageLoaded]);

  useEffect(() => {
    changeImageDimensions({
      naturalWidth: undefined,
      naturalHeight: undefined,
    });
    setSpeedZoomToggle(-1);
    setSpeedZoomTriggered(false);
    setFilteredAnnotations([], Number(image), true);

    setForceIncludedAnnotations([]);
  }, [image, setLoading]);

  const onVideoOrImageLoaded = useEventCallback((image) => {
    if (image.naturalHeight !== 10) {
      onLoad();
      setLoading(false);
      const naturalWidth = image.naturalWidth;
      const naturalHeight = image.naturalHeight;
      const duration = image.duration;
      const dims = {
        naturalWidth: naturalWidth,
        naturalHeight: naturalHeight,
        duration: duration,
      };

      // NOTABLE: If the image fails to load, dims default to 512x512.
      changeImageDimensions(dims);
    }
  });

  const [clientWidthBefore, setClientWidthBefore] = useState(0);
  const [widthScaleBefore, setWidthScaleBefore] = useState(0);

  const horizontalImageMarginSize = 100.0;
  const bottomImageMarginSize = 60.0;
  function calculateScaleMatrix(
    clientWidth: number,
    clientHeight: number,
    naturalWidth: number,
    naturalHeight: number
  ) {
    // Calculate maximum scale allowed on height and then use ratio to convert it to the width scale.
    const maxScale = naturalHeight / (clientHeight - bottomImageMarginSize);

    const scaleValue = (naturalWidth + horizontalImageMarginSize) / clientWidth;

    // Counterintuitively smaller scale values make the image bigger.
    return compose(
      translate(+horizontalImageMarginSize / 2 + naturalWidth),
      scale(Math.max(scaleValue, maxScale)),
      translate(-clientWidth)
    );
  }

  // Rescale based on:
  // - Window Width: When the browser window is resized, this indirectly changes the size of the view.
  // - widthScale: When the image viewer is resized directly.
  useEffect(() => {
    const newClientWidth = window.innerWidth;
    const difference = Math.abs(clientWidthBefore - newClientWidth);

    // Sometimes the window randomly changes by a few pixels.
    // Don't know why but here we ignore those jumps whilst still allowing regular drag resizing to function.
    if (
      (difference > 10 || widthScaleBefore !== widthScale) &&
      imageDimensions.naturalWidth
    ) {
      const newCanvasWidth = canvasEl.current.clientWidth;
      const newMatrix = calculateScaleMatrix(
        newCanvasWidth,
        canvasEl.current.clientHeight,
        imageDimensions.naturalWidth,
        imageDimensions.naturalHeight
      );

      setMatrix(newMatrix);
      setClientWidthBefore(newClientWidth);
      setWidthScaleBefore(widthScale);
    }
  }, [
    imageDimensions.naturalWidth,
    canvasEl.current,
    clientWidthBefore,
    setMatrix,
    widthScale,
    widthScaleBefore,
    window.innerWidth,
  ]);

  // Reset scaling when new image loads.
  useEffect(() => {
    if (
      imageDimensions?.naturalWidth &&
      (reviewMode === ReviewMode.None || !autoSpeedZoomInReviewModes)
    ) {
      const newCanvasWidth = canvasEl.current.clientWidth;
      const newMatrix = calculateScaleMatrix(
        newCanvasWidth,
        canvasEl.current.clientHeight,
        imageDimensions.naturalWidth,
        imageDimensions.naturalHeight
      );

      setMatrix(newMatrix);
    }
  }, [imageDimensions.naturalWidth, canvasEl.current]);

  const canvas = canvasEl.current;
  let clientWidth = 900;

  if (canvas && imageLoaded) {
    clientWidth = canvas.clientWidth;
    const clientHeight = canvas.clientHeight;
    setCanvasWidth(clientWidth);
    setCanvasHeight(clientHeight);

    if (canvas.getContext && pipes.length > 1) {
      // Sets the canvas rendering resolution NOT the size of the canvas on screen.
      canvas.width = clientWidth;
      canvas.height = clientHeight;

      // Get the drawing mechanism in order
      const ctx = canvas.getContext("2d");
      ctx.lineWidth = 1 / matrix.a;
      ctx.strokeStyle = `rgba(255,0,0,${matrix.a})`; // Transparency follows zoom
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Compute top left and size
      const size = {
        w: clientWidth / matrix.a,
        h:
          (imageDimensions.naturalHeight * clientWidth) /
          imageDimensions.naturalWidth /
          matrix.a,
      };

      ctx.beginPath();

      ctx.moveTo(
        imageTopLeft.x + pipes[0][0] * size.w,
        imageTopLeft.y + pipes[0][1] * size.h
      );
      // Draw along line
      for (let line_number = 1; line_number < pipes.length; line_number++) {
        ctx.lineTo(
          imageTopLeft.x + pipes[line_number][0] * size.w,
          imageTopLeft.y + pipes[line_number][1] * size.h
        );
      }

      ctx.stroke();
    } else {
      // Clear if there is no pipeline
      const ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  const thermalImage = connectedImages?.find((im) => im.thermal);
  const rgbImage = connectedImages?.find((im) => im.rgb);

  return (
    <>
      <CanvasContainer
        dragging={dragged}
        mat={matrix}
        imageLoaded={imageLoaded}
        reviewLoading={reviewLoading}
      >
        <Outlet />

        <AnnotationsList
          setAnnotationListScroll={setAnnotationListScroll}
          setSelectedRegions={setSelectedRegions}
          selectedRegions={selectedRegions}
          speedZoom={speedZoom}
          toggleBox={toggleBox}
          reviewMode={reviewMode}
          annotations={filteredAnnotations}
          unfilteredAnnotations={annotations ?? []}
          image={parseInt(image)}
          forceIncludedAnnotations={forceIncludedAnnotations}
          setForceIncludedAnnotations={setForceIncludedAnnotations}
        />
        <canvas
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            left: 0,
            top: 0,
            zIndex: 2,
            touchAction: "none",
          }}
          id="imageCanvas"
          ref={canvasEl}
        />

        {!!imageId && (
          <SpeedImage
            imagePosition={{
              topLeft: imageTopLeft,
              bottomRight: imageBottomRight,
            }}
            onLoad={onVideoOrImageLoaded}
            cdn={cdn}
            canvas={canvasEl.current}
          />
        )}
        {isThermal && thermalData.temperature.length > 10 && (
          <ThermalViewer
            imagePosition={{
              topLeft: imageTopLeft,
              bottomRight: imageBottomRight,
            }}
          />
        )}

        {!selectedPreviousDefectImage && reviewMode === ReviewMode.None && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              zIndex: 5,
              position: "absolute",
              imageRendering: "pixelated",
              backgroundColor: "rgba(0,0,0,0.8)",
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              overflow: "hidden",
              left: Math.min(imageBottomRight.x, canvasWidth - 45.0) || 0, // Clamp inside the view.
              top: Math.max(imageTopLeft.y, 0.0) || 0,
            }}
          >
            <Tooltip
              title={RGBImageTranslation}
              arrow
              placement="left"
              disableInteractive
            >
              <span>
                <IconButton
                  disabled={!rgbImage}
                  onClick={() =>
                    navigate(`/${customerId}/${rgbImage.id}${location.search}`)
                  }
                  className="connectedButton"
                >
                  <Image htmlColor={rgbImage ? "white" : "#777"} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={thermalImageTranslation}
              arrow
              placement="left"
              disableInteractive
            >
              <span>
                <IconButton
                  disabled={!thermalImage}
                  onClick={() =>
                    navigate(
                      `/${customerId}/${thermalImage.id}${location.search}`
                    )
                  }
                  className="connectedButton"
                >
                  <Whatshot htmlColor={thermalImage ? "white" : "#777"} />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title={"Pole"} arrow placement="left" disableInteractive>
              <span>
                <IconButton
                  disabled={!poleID}
                  onClick={() => {
                    if (searchParams.has("pole")) {
                      searchParams.delete("pole");
                    } else {
                      searchParams.set("pole", JSON.stringify(poleID));
                      searchParams.set("poleStatus", "true");
                    }
                    setSearchParams(searchParams);
                  }}
                  className="connectedButton"
                >
                  <div className="pole" style={{ transform: "none" }}>
                    <div className="insulatorRow">
                      <div className="insulator" />
                      <div className="insulator" />
                      <div className="insulator" />
                    </div>
                    <div className="crossarm" />
                    <div className="beam" />
                  </div>
                </IconButton>
              </span>
            </Tooltip>
            {!!image && (
              <Tooltip
                title={lidarImageTranslation}
                arrow
                placement="left"
                disableInteractive
              >
                <span>
                  <IconButton
                    disabled={!imageLidar}
                    onClick={() => {
                      window.name = "sq-map";
                      const LIDAR_URI = getLidarURL();
                      if (lidarWindow === null || lidarWindow?.closed) {
                        // No lidar window exists
                        const URL = `${LIDAR_URI}/${imageLidarProjectId}?scene=${imageLidar}&image=${image}${
                          demoMode ? "&demo=true" : ""
                        }`;
                        const lidarWindow = window.open(URL, "sq-lidar");
                        dispatch(setLidarWindow(lidarWindow));
                      } else {
                        lidarWindow.postMessage(
                          {
                            type: "OPEN-IMAGE",
                            mission: imageLidarProjectId,
                            project: imageLidar,
                            image: image,
                            demoMode,
                          },
                          LIDAR_URI
                        );
                        lidarWindow.focus();
                      }
                    }}
                    className="connectedButton"
                  >
                    <ThreeDRotation htmlColor={imageLidar ? "white" : "#777"} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div>
        )}

        <RegionShapes
          imagePosition={{
            topLeft: imageTopLeft,
            bottomRight: imageBottomRight,
          }}
          setGetData={setGetDate}
          lockedRegion={lockedRegion}
          hoveredRegion={hoveredRegion}
          fixableDefects={fixableDefects}
          imageLoaded={imageLoaded}
          selectedRegions={selectedRegions}
          annotations={filteredAnnotations}
          reviewMode={reviewMode}
        />
      </CanvasContainer>

      <Dialog
        sx={{ zIndex: 1500 }}
        open={getDate.visible}
        onClose={() =>
          setGetDate({
            visible: false,
            comment: "",
            isProcessDefect: false,
            callback: (e) => {},
          })
        }
      >
        <DialogTitle>{setDateTranslation}</DialogTitle>
        <DialogContent>
          <TextField
            type="date"
            fullWidth
            id="inputDate"
            defaultValue={new Date().toLocaleDateString("en-CA")}
          />
          {getDate.isProcessDefect && (
            <TextField
              minRows={3}
              multiline
              fullWidth
              id="inputComment"
              label="Comment"
              defaultValue={getDate?.comment}
              onKeyDown={(e) => e.stopPropagation()}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              getDate.callback(null);
              setGetDate({
                visible: false,
                comment: "",
                isProcessDefect: false,
                callback: (e) => {},
              });
            }}
          >
            {ClearTranslation}
          </Button>
          <Button
            className="errorButton"
            onClick={() =>
              setGetDate({
                visible: false,
                comment: "",
                isProcessDefect: false,
                callback: (e) => {},
              })
            }
            style={{ marginRight: 10 }}
          >
            {CloseTranslation}
          </Button>

          <Button
            className="secondaryButton"
            onClick={() => {
              const element = document.getElementById("inputDate");
              const comment = document.getElementById("inputComment");
              if (getDate.isProcessDefect) {
                if (element && comment) {
                  // @ts-ignore
                  getDate.callback(element.value, comment.value);
                } else {
                  toast.error(getDataFailedTranslation);
                }
              } else {
                if (element) {
                  // @ts-ignore
                  getDate.callback(element.value);
                } else {
                  toast.error(getDataFailedTranslation);
                }
              }
              setGetDate({
                visible: false,
                comment: "",
                isProcessDefect: false,
                callback: (e) => {},
              });
            }}
          >
            {SaveTranslation}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ImageCanvas;
