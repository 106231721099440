import {
  CloseOutlined,
  Done,
  Edit,
  MotionPhotosOffOutlined,
  NavigateBefore,
  NavigateNext,
  Replay,
  ThumbDownAlt,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Tooltip,
} from "@mui/material";
import { workflow_status } from "AppConstants";
import {
  getFilterActive,
  useCurrentProject,
  useDispatch,
  useSelector,
  useTranslation,
} from "hooks";
import { useCallback, useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { Link } from "react-router-dom";
import {
  MachineReviewActions,
  getMLReviewObjects,
  setExpandAnnotationsList,
  setMLReview,
  updateAnnotations,
  updateMarkers,
} from "state/actions";
import { useReviewControls } from "../hooks/reviewControls";
import OrderByDropDown from "./OrderByDropDown";

export default function MachineReview() {
  const dispatch = useDispatch();
  const machineObjects = useSelector((state) => state.objects.mlReviewObject);
  const filteredAnnotations = useSelector(
    (state) => state.image.filteredAnnotations
  );
  const objectTypes = useSelector((state) => state.objects.objectTypes);
  const projectID = useSelector((state) => state.mission?.id);
  const { skyqraft_hidden, show_dso_tso } = useSelector((state) => state.user);
  const jumpToImageText = useTranslation("JumpToImage");
  const cancelText = useTranslation("Cancel");
  const confirmText = useTranslation("Confirm");
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const currentImage = parseInt(params.image);
  const [searchParams, setSearchParams] = useSearchParams();
  const [JumpToDialogOpen, setJumpToDialogOpen] = useState(false);
  const handleCloseJumpToDialog = () => {
    setJumpToDialogOpen(false);
  };

  const currentProject = useCurrentProject();
  const skyqraftHiddenSetting = useSelector(
    (state) => state.user.skyqraft_hidden
  );
  const showDsoTso = useSelector((state) => state.user.show_dso_tso);

  const [sortingOrder, setSortingOrder] = useState("confidence_desc");
  const filterActive = getFilterActive(searchParams);

  const [goToImageByIndex, currentIndex] = useReviewControls({
    unreviewed_workflow_status: workflow_status.REVIEW_REQUESTED,
    tool_path: "machine-review",
    reviewObjects: machineObjects,
  });

  const updateAllAction = useCallback(
    async (action: MachineReviewActions) => {
      await Promise.all(
        filteredAnnotations.map(async (o) => {
          const object_id = parseInt(o.id);

          await Promise.all(
            o.types.map(async (type) => {
              if (!isDefect(type)) {
                await dispatch(
                  setMLReview(
                    currentImage,
                    object_id,
                    type,
                    action,
                    null,
                    false
                  )
                );
              }
            })
          );
        })
      );
      // Wait until all updates are done before fetching and rendering new markers and annotations
      dispatch(updateMarkers());
      dispatch(
        updateAnnotations(
          projectID ?? -1,
          currentImage,
          skyqraft_hidden,
          show_dso_tso
        )
      );
    },
    [currentImage, filteredAnnotations, goToImageByIndex, projectID]
  );

  const refreshAnnotations = useCallback(() => {
    dispatch(
      updateAnnotations(
        currentProject.id,
        currentImage,
        skyqraftHiddenSetting,
        showDsoTso
      )
    );
  }, [currentImage, skyqraftHiddenSetting, showDsoTso, updateAnnotations]);

  useEffect(() => {
    dispatch(getMLReviewObjects(sortingOrder));
  }, [sortingOrder, filterActive]);

  // Expand annotation list when you enter Machine Review. David request Jun 2024.
  useEffect(() => {
    dispatch(setExpandAnnotationsList(true));
  }, []);

  if (!machineObjects.imageIds) {
    return <></>;
  }

  let newUrl = location.pathname;
  const regex = /\/machine-review/gi;
  newUrl = newUrl.replaceAll(regex, "");
  const KEY = "bbox";
  const ENABLED_VALUE = "0";

  const toggleBoundingBox = () => {
    if (searchParams.has(KEY)) {
      searchParams.delete(KEY);
    } else {
      searchParams.append(KEY, ENABLED_VALUE);
    }
    setSearchParams(searchParams.toString(), { replace: true });
  };

  const isDefect = (typeId) => {
    const objectType = objectTypes.find((o) => o.id === typeId);
    return objectType ? objectType.issue : false;
  };

  // Check if any annotation is a defect.
  const hasAnyDefect = filteredAnnotations.some((annotation) =>
    annotation.types.some((id) => objectTypes.find((o) => o.id === id)?.issue)
  );

  return (
    <div className="reviewToolWrapper" style={{ marginLeft: "180px" }}>
      <div className="backgroundControll wide">
        <Tooltip title="Go to previous image" placement="left" arrow>
          <NavigateBefore
            className="navigationArrow"
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={() => {
              goToImageByIndex(currentIndex - 1);
            }}
          />
        </Tooltip>
        <div className="menuWrapper">
          <div className="circleWrapper wide">
            <div
              className={
                hasAnyDefect ? "circle grey disabled" : "circle lightGreen"
              }
            >
              <Tooltip
                title={hasAnyDefect ? "Unavilable for defects" : "Approve all"}
                placement="top"
                arrow
              >
                <Done
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() => {
                    if (!hasAnyDefect) {
                      updateAllAction("approve");
                    }
                  }}
                />
              </Tooltip>
            </div>
            <div
              className={
                hasAnyDefect ? "circle grey disabled" : "circle yellow"
              }
            >
              <Tooltip
                title={
                  hasAnyDefect
                    ? "Unavilable for defects"
                    : "Approve but hide all"
                }
                placement="top"
                arrow
              >
                <MotionPhotosOffOutlined
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() => {
                    if (!hasAnyDefect) {
                      updateAllAction("approve-hide");
                    }
                  }}
                />
              </Tooltip>
            </div>
            <div
              className={hasAnyDefect ? "circle grey disabled" : "circle red"}
            >
              <Tooltip
                title={
                  hasAnyDefect
                    ? "Unavilable for defects"
                    : "Set all as false positive"
                }
                placement="top"
                arrow
              >
                <ThumbDownAlt
                  sx={{ color: "#ffff" }}
                  className="thumb"
                  onClick={() => {
                    updateAllAction("deny");
                  }}
                />
              </Tooltip>
            </div>
          </div>
          <div className="subMenuWrapper">
            <Tooltip
              title="Toggle visibility for bounding boxes. Does not change anything on the image"
              placement="bottom"
              arrow
            >
              <VisibilityOutlined
                sx={{ color: "#ffff" }}
                onClick={toggleBoundingBox}
              />
            </Tooltip>
            <Tooltip title="Refresh annotations with changes from database">
              <Replay sx={{ color: "#ffff" }} onClick={refreshAnnotations} />
            </Tooltip>
            <Tooltip
              title="Open image editor in new tab"
              placement="bottom"
              arrow
            >
              <Link
                to={`annotate${location.search}`}
                target="_blank"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                <Edit sx={{ color: "#ffff" }} />
              </Link>
            </Tooltip>
            <OrderByDropDown setSelectedEntry={setSortingOrder} />
            <Tooltip title="Exit review mode" placement="bottom" arrow>
              <CloseOutlined
                sx={{ color: "#ffff" }}
                onClick={() => {
                  navigate(`${newUrl}${location.search}`);
                }}
              />
            </Tooltip>
          </div>
        </div>

        <Tooltip title="Go to next image" placement="right" arrow>
          <NavigateNext
            className="navigationArrow green"
            fontSize="large"
            sx={{ color: "#006FEB" }}
            onClick={() => {
              goToImageByIndex(currentIndex + 1);
            }}
          />
        </Tooltip>
      </div>
      <div
        className="reviewImagesCount"
        onMouseUp={() => setJumpToDialogOpen(true)}
        style={{ cursor: "pointer" }}
      >
        {currentIndex + 1}/{machineObjects.imageIds.length}
      </div>
      <Dialog
        open={JumpToDialogOpen}
        onClose={handleCloseJumpToDialog}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            // Remove 1 because arrays start at 0 but our displayed numbering starts at 1.
            const index = Number.parseInt(formData.get("index").toString()) - 1;

            goToImageByIndex(index);

            handleCloseJumpToDialog();
          },
        }}
      >
        <DialogTitle>{jumpToImageText}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="index"
            label="Index"
            type="number"
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJumpToDialog}>{cancelText}</Button>
          <Button type="submit">{confirmText}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
